<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-overlay
        absolute
        color="black"
        opacity="0.4"
        :value="loadingDetailUser"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        />
      </v-overlay>
      <XyzTransition
        appear
        xyz="fade down-3 delay-6"
      >
        <v-card
          v-if="!loadingDetailUser"
          class="auth-card"
        >
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <v-img
                :src="appLogo"
                max-height="30px"
                max-width="30px"
                alt="logo"
                contain
                class="me-3 "
              />

              <v-img
                :src="require('@/assets/images/svg/oriens-text.svg')"
                max-width="130px"
                alt="logo"
                contain
                eager
                class="app-logo me-3"
              />
            </router-link>
          </v-card-title>

          <div>
            <v-card-text class="text-center">
              <p class="text-subtitle-1 font-weight-semibold text--primary mb-2">
                Buat Password Baru 🔒
              </p>
              <p class="mb-2">
                Buat password baru untuk akunmu.
              </p>
            </v-card-text>

            <!-- login form -->
            <v-card-text>
              <v-form>
                <v-text-field
                  v-model="password"
                  outlined
                  :type="isPasswordVisible ? 'text' : 'password'"
                  label="Password"
                  placeholder="Password"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  hide-details="auto"
                  class="mb-4"
                  :rules="[lengthValidator(password, 8)]"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                />
                <v-text-field
                  v-model="confirmPassword"
                  outlined
                  :type="isPasswordConfirmVisible ? 'text' : 'password'"
                  label="Konfirmasi Password"
                  placeholder="Konfirmasi Password"
                  :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  hide-details="auto"
                  class="mb-4"
                  :rules="[ confirmedValidator(confirmPassword, password) ]"
                  @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
                />

                <v-btn
                  block
                  color="primary"
                  :loading="loadingResetPasswordUser"
                  :disabled="loadingResetPasswordUser"
                  @click="resetPassword"
                >
                  Buat Password Baru
                </v-btn>
              </v-form>
            </v-card-text>
          </div>
        </v-card>
      </XyzTransition>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <XyzTransition
      appear
      xyz="fade left-3"
    >
      <v-img
        class="auth-tree"
        width="247"
        height="185"
        src="@/assets/images/misc/tree.png"
      />
    </XyzTransition>

    <!-- tree  -->
    <XyzTransition
      appear
      xyz="fade right-3 delay-3"
    >
      <v-img
        class="auth-tree-3"
        width="377"
        height="289"
        src="@/assets/images/misc/tree-3.png"
      />
    </XyzTransition>
  </div>
</template>

<script>
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { confirmedValidator, lengthValidator } from '@core/utils/validation'
import useUser from '@/composables/useUser'
import router from '@/router'

export default {
  props: {
    query: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const {
      fetchUserByForgotPasswordCode, loadingDetailUser, userDetail,
      mutationResetPassword, loadingResetPasswordUser,
    } = useUser()

    const currentTab = ref(0)

    const password = ref('')
    const confirmPassword = ref('')
    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)

    onMounted(() => {
      fetchUserByForgotPasswordCode(props.query.code).catch(() => {
        router.push({ name: 'auth-login' })
      })
    })

    const resetPassword = () => {
      mutationResetPassword(props.query.code, password.value, confirmPassword.value).then(() => {
        router.push({ name: 'auth-login' })
      })
    }

    return {
      currentTab,

      password,
      confirmPassword,
      isPasswordVisible,
      isPasswordConfirmVisible,

      // themeConfig
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },

      confirmedValidator,
      lengthValidator,
      loadingDetailUser,
      userDetail,
      resetPassword,
      loadingResetPasswordUser,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
